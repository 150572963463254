/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model(props) {
  const { nodes, materials } = useGLTF("/ono-sendai-VII.glb");
  return (
    <group {...props} dispose={null}>
      <group position={[20.65, 0, 13.33]}>
        <group position={[-13.91, 33.7, -29.83]} rotation={[0, 0.82, 0]}>
          <group position={[-27.71, -6.55, 54.19]} rotation={[0, -0.17, 0]}>
            <group
              position={[-109.62, -27.15, -117.23]}
              rotation={[0, -0.65, 0]}
            >
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.visor_1.geometry}
                material={nodes.visor_1.material}
                position={[297.61, 13.34, -61.24]}
                material-color="#cdcdcd"  
                material-roughness="0.1" 
                material-metalness="0.8"                    
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.visor_cables.geometry}
                material={nodes.visor_cables.material}
                position={[156.74, 7.72, -57.51]}
                material-color="#212121"  
                material-roughness="0.5" 
                material-metalness="0.6"                   
              />
            </group>
            <group position={[-0.79, -5.32, -36.41]}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.usb_port_nub.geometry}
                material={nodes.usb_port_nub.material}
                position={[-38.88, -3.99, 88.89]}
                rotation={[0, -0.65, 0]}
                material-color="#E63946"  
                material-roughness="0.5" 
                material-metalness="0.3"                   
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.hdmi_nub.geometry}
                material={nodes.hdmi_nub.material}
                position={[-40.59, -10.05, 88.9]}
                rotation={[0, -0.65, 0]}
                material-color="#3b3b3b"  
                material-roughness="0.5" 
                material-metalness="0.3"                    
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.input_1.geometry}
                material={nodes.input_1.material}
                position={[-53.3, -4.39, 92.55]}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.input_2.geometry}
                material={nodes.input_2.material}
                position={[-53.3, -4.39, 92.55]}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.input_3.geometry}
                material={nodes.input_3.material}
                position={[-53.3, -4.39, 92.55]}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.scart_blue.geometry}
                material={nodes.scart_blue.material}
                position={[-57.81, -3.45, 97.04]}
                rotation={[Math.PI / 2, 0, 0]}
                material-color="#4ECDC4"  
                material-roughness="0.5" 
                material-metalness="0.3"                 
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.scart_grey.geometry}
                material={nodes.scart_grey.material}
                position={[-57.81, 4.63, 97.06]}
                rotation={[Math.PI / 2, 0, 0]}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.plug.geometry}
                material={nodes.plug.material}
                position={[-76.09, 3.61, 91.2]}
                rotation={[0, -0.65, 0]}
                material-color="#3b3b3b"  
                material-roughness="0.5" 
                material-metalness="0.3"                   
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.plug_1.geometry}
                material={nodes.plug_1.material}
                position={[-76.09, -11.41, 91.2]}
                rotation={[0, -0.65, 0]}
                material-color="#3b3b3b"  
                material-roughness="0.5" 
                material-metalness="0.3"                  
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.screws.geometry}
                material={nodes.screws.material}
                position={[-57.8, 0.59, 96.62]}
                rotation={[0, -0.65, 0]}
                material-color="#cdcdcd"  
                material-roughness="0.1" 
                material-metalness="0.8"                   
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.IO_plug_inner.geometry}
                material={nodes.IO_plug_inner.material}
                position={[-58.42, -4.24, 93.71]}
                rotation={[0, -0.65, 0]}
                material-color="#cdcdcd"  
                material-roughness="0.1" 
                material-metalness="0.8"                      
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.LAN_bits.geometry}
                material={nodes.LAN_bits.material}
                position={[-40.76, -2.74, 93.01]}
                rotation={[0, -0.65, 0]}
              />
            </group>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.ICE.geometry}
              material={nodes.ICE.material}
              position={[83.94, -24.31, 100.66]}
              rotation={[Math.PI / 2, 0, 2.63]}
              material-color="#E63946"  
              material-roughness="0.5" 
              material-metalness="0.3"                 
            >
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.ICE_silicon.geometry}
                material={nodes.ICE_silicon.material}
                position={[0.01, -11.38, -0.06]}
              />
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.screen.geometry}
              material={nodes.screen.material}
              position={[-12.62, 10.28, -52.97]}
              rotation={[0, 0, Math.PI / 6]}
              material-color="#3b3b3b"  
              material-roughness="0.5" 
              material-metalness="0.3"                 
            >
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.screen_glass.geometry}
                material={nodes.screen_glass.material}
                position={[2.84, 35.09, -0.03]}
                material-color="#cdcdcd"  
                material-roughness="0.1" 
                material-metalness="0.8"                 
              />
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.buttons.geometry}
              material={nodes.buttons.material}
              position={[25.57, -3.06, 12.37]}
              rotation={[0, -0.65, 0]}
              material-color="#cdcdcd"  
              material-roughness="0.1" 
              material-metalness="0.8"                   
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.console.geometry}
              material={nodes.console.material}
              position={[38.77, -0.22, -34.07]}
              rotation={[0, -0.65, 0]}
              material-color="#3b3b3b"  
              material-roughness="0.5" 
              material-metalness="0.3"               
            />
          </group>
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.logo.geometry}
          material={nodes.logo.material}
          position={[93.84, 13.09, 13.16]}
          material-color="#cdcdcd"  
          material-roughness="0.1" 
          material-metalness="0.8"            
        />
      </group>
    </group>
  );
}

useGLTF.preload("/ono-sendai-VII.glb");





