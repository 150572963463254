import * as React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import FeatherIcon from 'feather-icons-react';
import { motion } from 'framer-motion';
import { Suspense, useRef} from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, MeshReflectorMaterial, Environment, Loader } from '@react-three/drei'
import Cyberspace from '../components/cyberspace'

function Model({ ready, ...props }) {
  const ref = useRef()
  return (
    <Suspense fallback={null}>   

      <Cyberspace scale={0.03} position={[0, -1.2, 0]} rotation={[0, Math.PI * 1.5, 0]} />
       
  </Suspense>
  )
}

const CyberspacePage = () => (
  
  <Layout>
    

    <Helmet>
      <body className="body-dark"></body>
    </Helmet>

<section className="h-screen flex items-end content-end">

      <motion.div 
        initial={{x: 50, opacity: 0}}      
        animate={{x: 0, opacity: 1 }}
        transition={{duration: 0.5, type: "spring", ease: 'easeOut', bounce: 0.4  }}       
        className="absolute p-5 sm:mb-5 z-50">  

        <a href="/work#play">
          <FeatherIcon icon="corner-up-left" size="36" stroke="#222" className="mb-6"/>   
        </a>        

        <h1 className="display-1 mb-2">Cyberspace Deck</h1>
        <motion.hr 
          className="mb-2"
          initial={{width: 0}}
          animate={{width: "100%"}}
          transition={{duration: 1.5}}                  
        /> 
        <p className="display-3">C4D // R3F // Click &amp; drag to rotate</p>
      </motion.div>  


      <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 30, 5], fov: 20 }}> 
      <fog attach="fog" args={['#cdcdcd', 0, 80]} />
      <color attach="background" args={['#cdcdcd']} />
          <Model  />  
          <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -1.2, 0]}>
          <planeGeometry args={[170, 170]} />
          <MeshReflectorMaterial
            blur={[400, 100]}
            resolution={1024}
            mirror={0.75}
            mixBlur={1}
            opacity={2}
            depthScale={1.1}
            minDepthThreshold={0.4}
            maxDepthThreshold={1.25}
            roughness={0.5}
            color="#3b3b3b"
          />
        </mesh>   
        <mesh receiveShadow rotation-x={-Math.PI / 2} position={[0, -1.199, 0]}>
          <planeGeometry args={[100, 100]} />
          <shadowMaterial transparent color="black" opacity={0.4} />
        </mesh>
          <hemisphereLight intensity={0.2} />    
          <spotLight position={[10, 50, 0]} angle={0.25} penumbra={0.3} castShadow intensity={0.1} shadow-bias={-0.0001} />            
        <OrbitControls
        enablePan={false}
        enableZoom={false}
        minPolarAngle={Math.PI / 2.2}
        maxPolarAngle={Math.PI / 2.2}
      />     
            <Environment preset="warehouse" />      
      </Canvas>
      <Loader />  

  </section>
 
  </Layout>
)



export default CyberspacePage
